import _ from 'lodash';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { GET } from 'src/utils/services';
import { SectionHead } from 'src/components/cards';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { APP_IMG } from 'src/constants';
const WidgetFollow = dynamic(import('src/components/widgets/widget-follow'));

const Footer = ({ data }) => {
  const router = useRouter();
  const [footer, setFooter] = useState(null);
  const [company, setCompany] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const resMenu = await GET('/api/footer');
        const resCompany = await GET('/api/v1.0/navigations/company-group');
        !_.isEmpty(resMenu) && setFooter(resMenu);
        !_.isEmpty(resCompany) && setCompany(resCompany);
      } catch (err) {
        console.error('Footer', err);
      }
    })();
  }, []);
  return (
    <>
      <FooterComponents className='footer'>
        <section className='container footer-wrapper'>
          <div className='row footer-separator'>
            <div className='col-lg-3 col-12 footer footer-left'>
              <img
                className='logo-img'
                alt='logo-footer'
                title='logo-footer'
                src={`${APP_IMG}/static/logo-black.png`}
                loading='lazy'
                width='48'
                height='48'
              />
              <div className='footer-address' dangerouslySetInnerHTML={{ __html: footer }} />
              <div className='footer-connected'>
                <div className='mb-15 mb-lg-30'>
                  <SectionHead data={'stay connected'} />
                </div>
                {/* --------------------------------- SOCIAL --------------------------------- */}
                <WidgetFollow
                  //! DATALAYER
                  eventDataLayer={{
                    type: DATALAYER_TYPE.TRACK_POSITION,
                    router: router?.pathname,
                    section: 'follow',
                    position: 'on:footer'
                  }}
                />
              </div>
            </div>
            {/* ------------------------------------ CATEGORIES ------------------------------- */}
            {!_.isEmpty(data) && (
              <div className='col-6 col-lg-5 col-md-12 footer footer-center mb-30'>
                <div className='mt-30'>
                  <SectionHead data={'categories'} />
                </div>
                <div className='mt-15 category-list'>
                  {data?.map((item, index) => (
                    <div key={index} className='category-items'>
                      <a
                        href={`/${item.link || '#'}`}
                        aria-label={item.nameTh}
                        title={item.nameTh}
                        className='title-main'
                        //! DATALAYER
                        onClick={() =>
                          sendDataLayerGlobal({
                            type: DATALAYER_TYPE.TRACK,
                            router: router?.pathname,
                            section: 'footer',
                            data: {
                              block: '1',
                              title: item?.nameTh,
                              heading: 'categories'
                            }
                          })
                        }
                      >
                        {item.nameTh}
                      </a>
                      {!_.isEmpty(item?.sub) && (
                        <>
                          {item?.sub?.map((submenu, i) => (
                            <span key={i}>
                              <a
                                href={submenu?.link}
                                //! DATALAYER
                                onClick={() =>
                                  sendDataLayerGlobal({
                                    type: DATALAYER_TYPE.TRACK,
                                    router: router?.pathname,
                                    section: 'footer',
                                    data: {
                                      block: '1',
                                      title: submenu?.nameEng,
                                      heading: `categories:${submenu?.nameEng}`
                                    }
                                  })
                                }
                              >
                                {submenu?.nameEng}
                              </a>
                            </span>
                          ))}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* --------------------------------- PARTNER -------------------------------- */}
            {!_.isEmpty(company) && (
              <div className='col-12 col-lg-3 col-md-12 footer footer-right'>
                <div className='mt-30'>
                  <SectionHead data={'partner'} />
                </div>
                <ul>
                  {company.map((item, index) => (
                    <li key={index}>
                      <div>
                        <a
                          href={item.link}
                          target='_blank'
                          rel='noopener noreferrer nofollow'
                          aria-label='company'
                          title='company'
                          //! DATALAYER
                          onClick={() =>
                            sendDataLayerGlobal({
                              type: DATALAYER_TYPE.TRACK,
                              router: router?.pathname,
                              section: 'footer',
                              data: {
                                block: '2',
                                title: item?.nameEng,
                                heading: 'partner'
                              }
                            })
                          }
                        >
                          {item.nameEng}
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </section>
        <div className='footer-sonp'>
          <div className='container footer-item'>
            <div className='footer'>
              <img src={`${APP_IMG}/static/logo_sonp.png`} alt='logo_sonp' title='logo_sonp' width='180' height='60' />
            </div>
            <div className='footer footer-copyright'>
              <span>
                {`Ⓒ ${new Date().getFullYear()} -`} <p> SPRiNG</p>
                {'All Rights Reserved.'}
              </span>
              <div id='truehits_div'></div>
            </div>
          </div>
        </div>
      </FooterComponents>
    </>
  );
};

const FooterComponents = styled.footer`
  background-color: #ffe600;
  width: 100%;
  /* margin-top: 30px; */
  @media (max-width: 475px) {
    .section-head {
      .feed-bg {
        font-size: 16px;
      }
    }
  }
  .footer {
    a {
      transition: all 0.4s ease-in-out;
      &:hover {
        color: #fff !important;
      }
    }
  }
  .footer-wrapper {
    .footer-separator {
      justify-content: space-between;
      color: white;
      border-top: 2px solid rgba(255, 230, 0);
      padding-top: 18px;
      .widget-follow {
        justify-content: space-between;
      }
    }
    .footer-left {
      display: flex;
      flex-direction: column;
      .section-head {
        padding: 0;
      }
      .footer-address {
        word-wrap: break-word;
        margin: 20px 0;
        color: #000;
        font-weight: 300;
        font-size: 14px;
        p {
          &:nth-child(2),
          &:nth-child(4) {
            margin-bottom: 28px;
          }
          br {
            display: none;
          }
        }
      }
      img {
        max-width: 296px;
        width: 100%;
        height: auto;
      }
      .footer-connected {
        margin-top: auto;
      }
    }
    .footer-center {
      .section-head {
        padding: 0;
      }

      .category-list {
        column-count: 3;
        column-gap: 10px;
        .category-items {
          margin: 0;
          display: grid;
          grid-template-rows: 1fr auto;
          break-inside: avoid;
        }
      }
      @media (max-width: 690px) {
        display: none;
      }
    }
    .footer-right {
      .section-head {
        padding: 0;
      }
      ul {
        list-style: none;
        margin: 15px 0;
        padding: 0;
        @media (max-width: 690px) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 0 -15px;
        }
        li {
          /* margin: 0 0 12px; */
          padding: 0 0 12px;

          a {
            font-size: 15px;
            font-weight: 400;
            color: #000000;
            text-transform: uppercase;
            transition: all 0.5s ease-in-out;
            padding: 16px 10px;
            margin: 0 -10px;
          }
          @media (max-width: 690px) {
            width: 50%;
          }
          div {
            border-bottom: 1px solid #8c8c8c;
            padding: 3px 0;
          }
          @media (max-width: 475px) {
            /* margin: 0 0 5px; */
            padding: 0 15px;
            a {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .footer-item {
    display: flex;
    justify-content: space-between;
  }
  .footer-sonp {
    background-color: #000;
    padding: 0 0 5px 0;
    .footer-copyright {
      display: flex;
      align-items: center;
      color: rgba(255, 230, 0);
      text-align: center;
      font-size: 12px;
      font-weight: 300;
      @media (max-width: 690px) {
        flex-direction: column;
        justify-content: flex-end;
      }
      span {
        padding-left: 10px;
        font-size: 12px;
        font-weight: 300;
        a {
          color: rgba(255, 230, 0);
          transition: all 0.5s ease-in-out;
          /* padding: 16px 0; */
          &:hover {
            color: rgba(255, 230, 0);
          }
        }
      }
      p {
        display: inline-block;
        color: rgba(255, 230, 0);
      }
    }
  }
  .category-list {
    .title-main {
      font-size: 15px;
      font-weight: 500;
    }
    .category-items {
      padding-bottom: 28px;
      display: flex;
      flex-direction: column;
      span {
        a {
          font-size: 15px;
          font-weight: 300;
          text-transform: uppercase;
        }
      }
    }
  }
`;
export default Footer;
